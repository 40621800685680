@use '../../App.scss' as *;

.explorer_son_fonctionnement_container {
  max-width: 900px;
  margin: 1em;
  margin-left: auto;
  margin-right: auto;
}

.explorer_son_fonctionnement_content {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .formules {
    position: relative;
    display: flex;
    gap: 2em;

    .pastille {
      display: grid;
      align-items: center;
      position: absolute;
      top: 0vh;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 1em;
      border-radius: 20px;
      background-color: $delpfine_blue_dark_green;
      color: white;
      white-space: nowrap; /* Empêche le texte de se décomposer en plusieurs lignes */
      p {
        line-height: 1.8em;
        font-size: 14px;
      }
    }
    .formule {
      justify-content: space-between;
      width: fit-content;
      padding: 1em;
    }
  }
  .design-list {
    list-style: none !important;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;

    > li {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1em;
      margin: 0;
      padding: 1em;
      background-color: white;

      > img {
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .design-list {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 1em;
  }
}

@media screen and (max-width: 550px) {
  .formules {
    flex-direction: column;
    .formule {
      width: unset !important;
    }
  }
  .design-list {
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 1em;
  }
}
