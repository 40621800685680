@use '../../App.scss' as *;

.question > div {
  // font-size: 1.1em;
}

.response {
  // font-size: 1em !important;
}

.questionsAsk_container {
  background-color: rgb(249, 249, 249);
}

.questionsAsk_contener {
  max-width: 900px;
  p {
    text-align: left !important;
    font-size: 1em !important;
  }
}

.search_bar {
  display: flex;
  align-items: center;
  gap: 0.5em;

  > input {
    width: 50vw;
    max-width: 400px;
    padding: 0.5em;
    font-size: 16px;
    border-radius: 12px;
    border: unset;
  }

  .remove {
    padding: 0.6em;
    border-radius: 12px;
    transition: all ease 0.3s;
    color: $delpfine_blue_green;
    outline: unset !important;
    &:focus-visible {
      outline-color: rgba($delpfine_blue, 0.3) !important;
    }
    &:hover {
      color: white;
      background-color: $delpfine_blue_green;
    }
  }
}

.standalone_link {
  padding-top: 1em;
  svg {
    margin-right: 0.5em;
  }
  a {
    color: $delpfine_blue_green;
  }
}

.MuiPaper-root,
.MuiAccordion-root {
  box-shadow: none !important;
}

[class*='MuiPaper-root-MuiAccordion-root'] {
  padding: 1em;
  border-radius: 20px;
  &::before {
    background-color: rgba($delpfine_blue, 0.3) !important;
    height: 4px !important;
  }
}

// [class*='MuiPaper-root-MuiAccordion-root']::before {
//   background-color: rgba($delpfine_blue, 0.3) !important;
// }

@media screen and (max-width: 550px) {
  .search_bar {
    > input {
      width: 100%;
    }
  }
}
