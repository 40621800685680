@use '../../App.scss' as *;

.modalBg {
  // width: 100%;
  // height: 100%;
  margin: 0;
  padding: 0;
  display: none;
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #2021247a;

  > .modal_container {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 900px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    border-radius: 10px;
    background-color: $delpfine_white;
    box-shadow: 0px 4px 4px 0px rgb(61 61 61 / 41%);

    > .content {
      padding: 0 1em;
      p {
        display: inline;
      }

      a > p {
        color: #161616;
      }
    }

    h3 {
      color: $delpfine_blue_dark_green;
      margin: 0;
    }

    p {
      color: $delpfine_blue_dark_green;
      line-height: 1.7;
      font-size: 1.1em;
      margin: 0;
    }

    .btn_actions {
      padding: 0 1em;
      display: flex;
      gap: 1em;

      .modal_button {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0em 1em;
        border-radius: 7px;
        // box-shadow: 0px 3px 0px 0px rgb(0 0 0 / 36%);
        box-shadow: inset 0px -4px 0px 0px rgba(0, 0, 0, 0.36);

        font-family: 'Montserrat';
      }

      .buttonAgree {
        background-color: $delpfine_light_blue;
        color: $delpfine_white;
      }

      .buttonDisagree {
        background-color: #4a4a4a6e;
        color: $delpfine_white;
      }
      &:hover {
      }
    }

    &.cookie_modal {
      width: 100%;
      height: fit-content;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}

.modal_visible {
  display: flex;
}

.modal_hidden {
  display: none;
}
