@use '../../App.scss' as *;

.temoignage_container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  max-width: 900px;

  > p {
    text-align: center;
  }

  .temoignage {
    margin: 1em 0;
    padding: 1em;
    display: flex;
    box-shadow: 0px 6px 0px 0px rgba($delpfine_blue, 0.5) !important;
    border: 1px solid $delpfine_blue;
    background-color: $delpfine_blue;
    color: $delpfine_white;

    p {
      text-align: left !important;
      font-size: 1em !important;
    }

    > img {
      margin: 1em;
    }

    .content {
      margin: 0 1em;

      // font-size: 18px;
      > div {
        margin: 1em 0 !important;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .temoignage {
    flex-direction: column;
    align-items: center;
  }
}
