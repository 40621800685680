@use '../../App.scss' as *;

.footer_container {
  color: white;

  a {
    color: white;

    &:hover {
      color: white;
    }
  }

  position: static;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;

  //   color: white;
  .container_adresse {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1em;
    padding: 1em;
    color: black;
    background-color: rgb(251, 251, 251);

    .networks_links {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      a {
        display: flex;
        svg {
          width: 50px;
        }
      }
      .youtube > svg {
        fill: red;
      }
      .linkedin > svg {
        fill: rgb(10, 102, 194);
      }
      .facebook > svg {
        fill: rgb(8, 102, 255);
      }
    }

    div {
      z-index: 1;
      background: #ffffff45;
      border-radius: 12px;
    }

    > img {
      z-index: 0;
      position: absolute;
      left: 8vw;
      top: 0vh;
      transform: translate(-50%, 0);
      transition: 1s all ease;
    }

    a {
      color: black;

      &:hover {
        color: black;
      }
    }

    .adresse {
      display: flex;
      flex-direction: column;

      > div > p {
        user-select: unset;
      }

      a {
        margin: 1em;
      }
    }

    .container_gmaps {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      > .iframe_map {
        margin: 1em;
        max-width: 700px;
        height: 400px;
        width: 100%;
        border-radius: 20px;
      }
    }
  }

  .container_bottom_footer {
    padding: 1em;
    background: rgb(139, 102, 67);
    background: linear-gradient(
      159deg,
      rgba(139, 102, 67, 1) 0%,
      rgba(152, 122, 97, 1) 50%,
      rgba(221, 190, 168, 1) 100%
    );

    .container_links {
      display: flex;
      justify-content: center;
      gap: 1em;
      margin: 1em;
    }

    .container_legals {
      display: flex;
      justify-content: center;

      > a {
        padding: 1em;
      }
    }

    .copyright {
      text-align: center;
    }
  }
}

@media screen and (max-width: 550px) {
  .container_adresse {
    > img {
      position: absolute;
      left: 0vw !important;
      top: 0vh !important;
      transform: translate(-50%, 0) rotate(20deg) !important;
    }
  }
}
