// @import url(_root.scss);
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: alergia-regular;
  src: url(../public/fonts/AlergiaNormal-Regular.otf);
  font-weight: 300;
  font-display: auto;
}

@font-face {
  font-family: apercu-regular;
  src: url(../public/fonts/apercu_regular_pro.otf);
  font-weight: 300;
  font-display: auto;
}

@font-face {
  font-family: bendungan-signature;
  src: url(../public/fonts/Bendungan_Signature.otf);
  font-weight: 300;
  font-display: auto;
}

@font-face {
  font-family: kalam-regular;
  src: url(../public/fonts/Kalam-Regular.ttf);
  font-weight: 300;
  font-display: auto;
}

@font-face {
  font-family: Montserrat-variable;
  src: url(../public/fonts/Montserrat-variable.ttf);
  font-weight: 300;
  font-display: auto;
}

@font-face {
  font-family: BarlowCondensed-Regular;
  src: url(../public/fonts/BarlowCondensed-Regular.ttf);
  font-weight: 300;
  font-display: auto;
}

@font-face {
  font-family: LondrinaSolid-Regular;
  src: url(../public/fonts/LondrinaSolid-Regular.ttf);
  font-display: auto;
}

@font-face {
  font-family: LondrinaSolid-Light;
  src: url(../public/fonts/LondrinaSolid-Light.ttf);
  font-display: auto;
}

$delpfine_white: #fff;
$delpfine_light_white: #fafafa;
$delpfine_very_light_grey: rgb(240, 240, 240);
$delpfine_light_grey: rgb(231, 231, 231);
$delpfine_medium_grey: rgb(190, 190, 190);
$delpfine_default_black: rgb(63, 63, 63, 1);

$delpfine_blue: #63aec3;
$delpfine_light_blue: #63aec3;
$delpfine_dark_blue: #338197;
$delpfine_blue_green: #008783;
$delpfine_blue_dark_green: #003f3f;
$delpfine_blue_dark_green_opaque: #003f3fe3;

body {
  font-family: apercu-regular, sans-serif, 'Google Work', 'IBM Plew Serif';
  margin: 0;
  padding: 0;
  // min-height: 100vh;
  height: 100vh;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.3em 0;
  font-family: BarlowCondensed-Regular, sans-serif, 'Google Work',
    'IBM Plew Serif';
  font-weight: 100;
  // user-select: none;
}

h1 {
  font-size: 2.7em;
  font-family: kalam-regular;
  font-family: LondrinaSolid-Light;
  text-align: center;
}

.citation {
  font-family: kalam-regular;
  text-align: center;
}

h2 {
  color: $delpfine_blue_dark_green;
  font-size: 2em;
  &.hero_text {
    font-size: 40px !important;
  }
}

h3 {
  color: $delpfine_blue_green;
  font-size: 1.8em;
}

h4 {
  font-size: 1.5em;
}

p {
  font-size: 18px;
  margin: 0;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  line-height: 1.5;
  // font-family: Montserrat-variable, sans-serif, 'Google Work', 'IBM Plew Serif';
  // user-select: none;
  &.hero_text {
    font-size: 26px !important;
  }
}

p > strong {
  color: $delpfine_dark_blue;
  font-weight: 500;
}

.text_bold {
  font-family: alergia-regular, sans-serif, 'Google Work', 'IBM Plew Serif';
}

.sub_text {
  color: grey;
}

.sub_sub_text {
  color: grey;
  font-size: 0.8em;
}

.indent {
  margin-left: 1em;
}

div > figure {
  border-radius: 7px;
  overflow: hidden;

  &:hover .zoom_in_img {
    transform: scale(1.5);
  }
}

div > figure > img {
  width: 100%;
  height: auto;
  border-radius: 7px !important;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  line-height: 3em;
  margin: 0 1em;
}

.list_anim {
  > li {
    padding: 1em;
    margin: 0;
  }
}

a {
  color: $delpfine_blue_green; //rgb(61, 61, 61);
  text-decoration: none;
  list-style-type: none;
  transition: 0.5s all ease;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.cta-link {
}

.justify {
  text-align: justify;
}

.glass {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.conseil {
  border-radius: 20px;
  padding: 1em;
  background-color: rgb(250, 250, 250);
}

.text-align-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mb-4 {
  margin-bottom: 4em;
}
.mb-5 {
  margin-bottom: 5em;
}

.m-1 {
  margin: 1em;
}
.m-2 {
  margin: 2em;
}
.m-3 {
  margin: 3em;
}
.m-4 {
  margin: 4em;
}
.m-5 {
  margin: 5em;
}
.max-width-900 {
  // min-width: 900px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.grey-bg {
  background-color: rgb(249, 249, 249);
}

.sub_container_light {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  // margin: 1em;

  transition: opacity 1s ease-in-out;
}

.spaced {
  padding: 3em 1em;
}

/*ANIMATIONS*/

.anim {
  opacity: 0;
}

.show {
  opacity: 1;
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-left {
  animation: fade-in-left 1s ease-in 0s 1 normal forwards;
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-from-right {
  animation: fade-in-right 1s ease-in 0s 1 normal none;
}

.fade-in-right {
  animation: fade-in-right 1s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none;
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.show-up {
  animation: showup 1s ease-in 0s 1 normal none;
}

@keyframes showup {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slidein {
  0% {
    margin-left: -800px;
  }

  20% {
    margin-left: -800px;
  }

  35% {
    margin-left: 0px;
  }

  100% {
    margin-left: 0px;
  }
}

.reveal {
  animation: reveal 1s ease-in 0s 1 normal none;
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }

  20% {
    opacity: 1;
    width: 0px;
  }

  30% {
    width: 355px;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 355px;
  }
}

.spin-out {
  animation: spin-and-delete 1s ease-in forwards;
}

@keyframes spin-and-delete {
  0% {
    transform: rotateY(0);
    filter: hue-rotate(0);
  }

  80% {
    transform: rotateY(360deg);
    filter: hue-rotate(180deg);
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.spin-in {
  animation: spin-and-showup 1s ease-in forwards;
}

@keyframes spin-and-showup {
  0% {
    opacity: 0;
  }

  20% {
    transform: rotateY(0);
    filter: hue-rotate(0);
  }

  100% {
    transform: rotateY(360deg);
    filter: hue-rotate(180deg);
    opacity: 1;
  }
}

.scale-in-back-bottom {
  animation: scale_in_backward_bottom 1s ease 0s 1 normal forwards;
}

@keyframes scale_in_backward_bottom {
  0% {
    transform: scale(2);
    transform-origin: 50% 0%;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}

.scale-in-bottom {
  animation: scaleInBottom 1s ease-in-out 0s 1 normal forwards;
}

@keyframes scaleInBottom {
  0% {
    transform: scale(0);
    transform-origin: 50% 100%;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}

//GLOBAL MEDIA SCREEN

@media screen and (max-width: 850px) {
}

@media screen and (min-width: 1180px) {
}

@media screen and (max-width: 550px) {
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 25px;
    &.hero_text {
      font-size: 30px !important;
    }
  }
  h3 {
    font-size: 23px;
  }
  p {
    font-size: 16px;
    &.hero_text {
      font-size: 22px !important;
    }
  }
}
