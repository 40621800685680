@use '../../App.scss' as *;

.header_container {
  z-index: 900;
  background-color: rgb(255, 255, 255);
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: unset;
  transition: all 0.5s ease-in-out;

  > nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    margin: 1em 0;

    > h1 {
      margin: 1em;
      margin-bottom: 0;
    }

    .delpfine_logo {
      margin: 1em;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        height: 150px;
      }
    }

    .logo_container {
      z-index: 3;
      display: flex;
      align-items: center;
      transition: all ease 0.5s;
    }

    .titles_container {
      transition: all ease 0.5s;

      > h3 {
        margin: 0;
      }
      > h4 {
        color: #000000;
      }
    }

    .links_container {
      z-index: 2;
      display: flex;
      gap: 0.5em;

      .underline.reserver_seance {
        color: $delpfine_dark_blue;
        border: 2px solid $delpfine_dark_blue;
      }

      > a {
        color: rgb(61, 61, 61);
        text-decoration: none;
        list-style-type: none;
        transition: 0.5s all ease;
        > p {
          padding: 1em;
        }
        white-space: nowrap;
        border-radius: 7px;
        // font-size: 1.5em;
        font-family: BarlowCondensed-Regular;

        &:hover {
          color: #000;
          background-color: rgba(60, 88, 55, 0.116);
        }

        &.active-link {
          color: $delpfine_blue;
        }
      }
    }

    .language_selector {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
      margin: 0 1em;

      > select {
        padding: 1em 0em;
        background-color: #00000000;
        border: 0;
        position: relative;
        cursor: pointer;
      }
    }

    .menu-hamburger {
      z-index: 999;
      display: none;
      cursor: pointer;
      right: 0.5em;
      border-radius: 1000%;
      border: 2px solid rgba(140, 255, 184, 0);
      margin: 0 1em;

      padding: 0.5em;

      &:hover {
        border: 2px solid rgba(140, 255, 184, 0.226);
      }
    }
  }
}

// $base: 0.6rem;

// .container_chevron {
//   position: absolute;
//   left: 50%;
//   top: 28vh;
//   transform: translate(0%, -50%);
//   z-index: 1;
//   transform: translate(0%, -50%);
//   > div {
//     color: white;
//   }
//   transition: all 1s ease-in-out;
// }

// .chevron {
//   position: absolute;
//   width: $base * 3.5;
//   height: $base * 0.8;
//   opacity: 0;
//   transform: scale(0.3);
//   animation: move-chevron 3s ease-out infinite;
// }

// .chevron:first-child {
//   animation: move-chevron 3s ease-out 1s infinite;
// }

// .chevron:nth-child(2) {
//   animation: move-chevron 3s ease-out 2s infinite;
// }

// .chevron:before,
// .chevron:after {
//   content: '';
//   position: absolute;
//   top: 0;
//   height: 100%;
//   width: 50%;
//   background: white;
// }

// .chevron:before {
//   left: 0;
//   transform: skewY(30deg);
// }

// .chevron:after {
//   right: 0;
//   width: 50%;
//   transform: skewY(-30deg);
// }

.sticky-header {
  position: fixed;
  height: unset !important;
}

.header_container {
  &.sticky-header {
    border-bottom: 4px solid $delpfine_dark_blue;
    box-shadow: 0px 0px 38px rgba(128, 128, 128, 0.349);
    gap: unset !important;
    margin: unset !important;
    > .container_chevron {
      height: 0%;
      opacity: 0;
    }

    > nav {
      margin: 0;
      flex-direction: row !important;
      .logo_container {
        > img {
          height: 60px;
          width: auto;
        }
        > .titles_container {
          h3 {
            font-size: 1.4em;
          }
          h4 {
            font-size: 1em;
          }
          p {
            font-size: 0.8em;
          }
        }
      }
    }
  }
}

.header_container.sticky-header
  > .links_container.active
  + .header_container.sticky-header
  .language_selector {
  position: fixed !important;
  bottom: 1em !important;
  left: 50%;
}

// @keyframes move-chevron {
//   25% {
//     opacity: 1;
//   }
//   33.3% {
//     opacity: 1;
//     transform: translateY($base * 3.8);
//   }
//   66.6% {
//     opacity: 1;
//     transform: translateY($base * 5.2);
//   }
//   100% {
//     opacity: 0;
//     transform: translateY($base * 8) scale(0.5);
//   }
// }

@media screen and (max-width: 1250px) {
  .header_container {
    &.sticky-header {
      > nav {
        .links_container:not(.active) + .language_selector {
          > select {
            width: 1.3vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1325px) {
  .header_container {
    &.sticky-header {
      > nav {
        .links_container:not(.active) + .language_selector {
          > select {
            width: 1.3vw;
          }
        }
      }
    }
  }
  .header_container.sticky-header {
    > nav > .menu-hamburger {
      display: block !important;
    }
    .links_container:not(.active),
    .links_container:not(.active) + .language_selector {
      display: none !important;
    }

    > nav {
      justify-content: space-between;
    }
  }
}

@media screen and (min-width: 1325px) {
  .header_container {
    &.sticky-header {
      > nav {
        .logo_container {
          > .titles_container {
            font-size: 0;
            opacity: 0%;
            h3 {
              font-size: 0;
            }
            h4 {
              font-size: 0;
            }
            p {
              font-size: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 830px) {
  .header_container {
    &.sticky-header {
      > nav {
        .logo_container {
          > .titles_container {
            opacity: 0%;
            font-size: 0;
            h3 {
              font-size: 0;
            }
            h4 {
              font-size: 0;
            }
            p {
              font-size: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .menu-hamburger {
    display: block !important;
  }

  .header_container .links_container,
  .header_container
    .links_container:not(.active)
    + .header_container
    .language_selector {
    display: none !important;
  }

  .header_container > nav {
    justify-content: space-between;
  }
}

@media screen and (max-width: 710px) {
  .links_container.active + .menu-hamburger {
    position: fixed;
  }

  .header_container > nav {
    .logo_container {
      // gap: 1em;
      flex-direction: column !important;
      text-align: center;
    }
  }
}

@media screen and (max-width: 490px) {
  .titles_container {
    h3 {
      font-size: 5.5vw;
    }
    h4 {
      font-size: 4.5vw;
    }
    p {
      font-size: 3vw;
    }
  }
  .delpfine_logo {
    height: 80px;
    width: auto;
    &:hover {
      height: 100px !important;
    }
  }
}

.links_container.active {
  z-index: 1;
  opacity: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  background-color: $delpfine_blue_dark_green_opaque !important;
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;

  > a {
    color: white !important;
    text-align: center;
    width: 100%;

    &:hover {
      background-color: rgba(255, 255, 255, 0.199) !important;
    }
  }
}

.links_container.active + .menu-hamburger {
  display: block !important;
  fill: white !important;
}

.header_container:not(.sticky-header) {
  .links_container.active + .menu-hamburger {
    top: 1em !important;
    right: 0.5em !important;
  }
}
