@use '../../App.scss' as *;

.accompagnement_container {
  max-width: 900px;
  margin: 1em;
  margin-left: auto;
  margin-right: auto;
}

.accompagnement_content {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .sub_container_light {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 1em;
  }
}

.list_anim {
  user-select: none;
  list-style: none;

  >li {
    transition: 0.5s all ease-in-out;
    padding: 1em;

    &:hover {
      margin-left: 2em;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      //   border: 1px solid rgba(255, 255, 255, 0.3);
    }

    &.hovering {
      color: rgb(211, 211, 211);
    }
  }
}

ul {
  margin: 0;

  >li {
    // padding: 0 1em;

    transition: 0.5s all ease-in-out;
    border-radius: 20px;

    &:hover {
      //   color: rgb(0, 61, 53);
    }

    &.hovering {}
  }
}

.formules_container {
  display: flex;
}

@media screen and (max-width: 550px) {

  .sub_container_light {
    .sub_container_light {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .formules_container {
    flex-direction: column;

    ul>li {
      margin-bottom: 1em !important;
    }
  }
}