@use '../../App.scss' as *;

.alert {
  z-index: 999;
  position: fixed;
  margin: 0 0.5em;
  right: 0;
  top: 10vh;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.alert.open.fade-in-right {
  opacity: 1;
}
