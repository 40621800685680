@use '../../App.scss' as *;

.ressource_container {
  max-width: 900px;
  margin: 1em;
  margin-left: auto;
  margin-right: auto;
}

.text_receve_docs {
  p {
    font-weight: 500;
  }
}

.ressource_content {
  .video-wrapper {
    border-radius: 12px;
    margin: 2em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    padding: 3em;
    background-color: rgb(249, 249, 249) !important;

    p {
      font-size: 1em;
      text-align: left;
    }

    > .video {
      border-radius: 12px;
      width: 100%;
      height: 450px;
    }
  }

  .ressources-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 2em;
    margin-top: 2em;
  }
}

.free_download {
  margin: 2em 0;
  background-color: rgb(249, 249, 249);
  border-radius: 12px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
      text-align: center;
      color: $delpfine_blue_dark_green;
      min-width: 200px;
      max-width: 300px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 550px) {
  .ressource_content {
    .ressources-grid {
      grid-template-columns: 1fr;
    }
  }
}
