@use '../../App.scss' as *;

.aPropos_container {
  margin: 1em;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .max-width-900 {
    min-width: 900px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  > h2 {
    text-align: center;
    margin: 1em;
  }

  .aPropos_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    > .sub_container_light {
      transition: opacity 1s ease-in-out;
      margin: 1em;
      > p {
        margin: 1em;
      }
    }
    ul {
      margin: 0 1em;
    }
    .presentation {
      position: relative;
      display: flex;
      flex-direction: row;
      > img {
        margin: 0 2em 0 0;
        transition: 0.5s all ease-in-out;
      }

      > div {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 20px;
      }
    }

    .caracteristique {
      display: flex;
      gap: 1em;
      align-items: flex-start;
      justify-content: center;
      padding: 1em;
      // background-color: $delpfine_dark_blue;
      border-radius: 20px;
      text-align: center;
      color: black;
      // text-shadow: $delpfine_dark_blue 0px 0px 38px;

      .container_bulle {
        padding: 1em 0.5em;
        max-width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;

        p:nth-child(1) {
          font-size: 2vw;
        }
        p {
          max-width: 400px;
          font-size: 1vw;
        }
        // align-items: center;

        .bulle {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 2em;
          background-color: $delpfine_blue;
          // width: 90px;
          height: 90px;
          width: 90px;
          font-weight: 500;
          border-radius: 100%;
          p {
            font-family: kalam-regular;
            color: white;
          }
        }
      }
    }
  }
}

.presentation_img_container {
  display: grid;
  justify-content: center;
}

@media screen and (max-width: 1472px) {
  .caracteristique {
    flex-direction: column;
    align-items: center !important;

    .container_bulle {
      max-width: 80% !important;
      p:nth-child(1) {
        font-size: 2em !important;
      }
      p {
        font-size: 1em !important;
      }
      // align-items: center;

      // .bulle {
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   justify-content: center;
      //   padding: 2em;
      //   background-color: $delpfine_blue;
      //   // width: 90px;
      //   // height: 90px;
      //   // min-width: max-content;
      //   font-weight: 500;
      //   border-radius: 100%;
      // }
    }
  }
}

#cards_a_propos {
  .card-container {
    z-index: 1;
  }
  .card {
    width: 50vw;
    // max-width: unset;
  }
}

#cards_a_propos2 {
  .card-container {
    z-index: 0;
    width: 100%;
  }
  .card {
    width: 100vw;
    max-width: 1400px;
  }
}

#cards_a_propos,
#cards_a_propos2 {
  // display: flex;
  // flex-direction: column;
  gap: 0;
  .card-front,
  .card img {
    border-radius: 0;
  }

  // .card-front h3 {
  //   color: white;
  //   font-size: 2em;
  //   background-color: rgba(255, 255, 255, 0.63);
  // }

  .card-back p,
  .card-back h4 {
    z-index: 100;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    font-size: 18px;
    padding: 1em;
  }
}

#card_accomp1:hover,
#card_accomp2:hover {
  transform: rotateX(180deg);
}

@media screen and (max-width: 550px) {
  .presentation {
    align-items: center !important;
    flex-direction: column !important;
    > img {
      width: 200px;
      height: fit-content;
      // position: absolute;
      // top: -10vh !important;
      // left: -20vw !important;
      // transform: rotate(30deg) !important;
      // left: 0;
      // z-index: -1;
      // margin: 0;
      // width: 150px;
      // height: auto;
    }
  }

  .presentation:nth-of-type(2) {
    flex-direction: column-reverse !important;
  }

  #cards_a_propos {
    .card {
      height: 700px;
    }
  }
}
