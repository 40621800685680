@use '../../App.scss' as *;

.legal_notice_container {
  max-width: 900px;
  margin: 1em;
  margin-left: auto;
  margin-right: auto;
}

.legal_notice_content {
  p {
    font-size: 1em;
  }

  display: flex;
  flex-direction: column;
  gap: 1em;

  > .sub_container_light {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 1em;
  }
}

@media screen and (max-width: 550px) {
}
