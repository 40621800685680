@use '../../App.scss' as *;

.newsletter_container {
  max-width: 900px;
  margin: 1em;
  margin-left: auto;
  margin-right: auto;
}

.newsletter_content {
  transition: all 0.3s ease-in-out;
  form button {
    margin: 10px;
    padding: 10px;
    background-color: $delpfine_blue_dark_green;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media screen and (max-width: 550px) {
}
