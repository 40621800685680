@use '../../App.scss' as *;

.gimic_wrapper:first-of-type {
  animation: showup 5s ease-in-out;
}

.home_container {
  // max-width: 1500px;
  // background-image: url("../../..//public/image/plantes_bg1.jpg");
  margin-left: auto;
  margin-right: auto;
}

.home_content {
  display: flex;
  flex-direction: column;
  gap: 2em;

  h1,
  h2,
  h3,
  h4 {
    text-align: center;
  }

  .sub_container p {
    max-width: 900px;
    // text-align: center;
  }
}

.sub_container {
  margin: 1em;
  display: flex;
  gap: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.small {}

  &.full {
    // height: 100vh;
  }

  &.essence {
    >div {
      text-align: center;
    }

    // background: rgb(42, 42, 42);
    // background: radial-gradient(
    //   circle,
    //   rgb(0, 44, 65) 0%,
    //   rgba(0, 0, 0, 1) 100%
    // );
    // color: white;

    // > p {
    //   font-size: 1.5em;
    // }
  }
}

.illustration_1 {
  border-radius: 20px;
}

.image_container {
  display: grid;
  place-items: center;
  position: relative;
  margin: 0;

  .gimic_wrapper {
    outline: 2px solid white;
    outline-offset: -0.5em;
    position: absolute;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba($delpfine_blue, 0.8);

    >div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      gap: 3em;
      align-items: center;
      text-align: center;
      width: 100%;

      h2,
      p {
        letter-spacing: 0.1em;
        text-shadow: rgb(66, 66, 66) 0px 0px 38px;
        color: white;
        font-family: Montserrat-variable;
        font-family: LondrinaSolid-Regular;
        margin: 0;
        line-height: 1.5em;
        padding: 0 1em;
        // width: 95vw;
        max-width: 800px;
      }

      p {
        font-family: LondrinaSolid-Regular;
        top: 60%;
        font-size: 1.2em;
        max-width: 850px;
        // line-height: 3em;
      }
    }
  }

  .image-wrapper {
    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.image_container .image-wrapper {
  height: 73vh;
  width: 100vw;
}

// .grace_a_coaching {
//   p {
//     // font-size: 1.7em;
//     // font-family: BarlowCondensed-Regular;
//     // font-weight: 900;
//   }
// }

.accompagnement {
  margin: 5em;
}

#tilt {
  transition: box-shadow 0.1s, transform 0.1s;
}

#tilt:hover {
  box-shadow: 0px 0px 30px rgba(104, 104, 104, 0.568);
  cursor: pointer;
}

/* Methodology.css */
.methodologie {
  display: flex;
  align-items: center;
}

.cards_methodologie {
  display: flex;
  gap: 1em;

  .card-container {
    // perspective: 1000px;
    // max-width: 550px;
    // height: 350px;

    .card {
      width: 50vw;
      max-width: 700px;
      height: 450px;
      position: relative;
      transform-style: preserve-3d;
      transition: transform 0.6s;
    }

    .card-front,
    .card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 12px;
    }
  }
}

.card-back {
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 12px;
  // filter: blur(1px);
}

.card-front p,
.card-front h3 {
  margin: 0 !important;
  padding: 1em;
  text-align: center;
  color: white;
  background-color: #00000029;
  font-size: 2.3em;
  text-shadow: rgb(0 0 0) 0px 0px 20px;
  font-family: BarlowCondensed-Regular;
  font-weight: 500;
}

#card_offre1:hover,
#card_offre2:hover {
  transform: rotateY(180deg);
}

.card-back p {
  font-size: 1.5em;
  text-shadow: unset;
}

.card-container:nth-child(1) .card .card-back {
  background-position: center;
}

.card-container:nth-child(2) .card .card-back {
  background-position: right;
}

#back_offre1_img {
  background-image: url('../../assets/images/understand_yourself.avif');
}

#back_offre2_img {
  background-image: url('../../assets/images/understand_yourself2.avif');
}

.modalites {
  padding: 1em;

  >div {
    max-width: 900px;
    position: relative;
    border-radius: 20px;

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }

    >.image_hover {
      position: absolute;
      top: 0;
      bottom: 3px;
      width: 100%;
      display: grid;
      place-items: center;
      backdrop-filter: blur(3px);
      border-radius: 20px;
      overflow: hidden;

      >p {
        text-align: center;
        margin: 0 !important;
        max-width: 750px !important;
        font-size: 3vw !important;
        text-shadow: rgb(0 0 0) 0px 0px 7px;
        // width: max-content;
        padding: 1em;
        color: white;
        font-family: BarlowCondensed-Regular;
        font-weight: 900;
      }
    }
  }
}

.intro_text,
.container-sentiments-personnels {
  >div {
    // padding: 0 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
}

.intro_text {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // gap: 1em;
  position: relative;

  >img {
    // z-index: -1;
    width: 100vw;
    height: 90vh;
    object-fit: cover;
  }

  .meandre_text {
    width: 40vw;
    position: absolute;
    top: 40%;
    right: 0%;
    transform: translate(0%, -50%);
    right: 0em;
    color: white;
    font-family: BarlowCondensed-Regular;
    font-weight: 900;
    font-size: 3em;
  }

  >div {
    // padding: 1em;
  }
}

.btn_rervervation {
  width: fit-content;
  margin: 1em;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  color: white;
  border-radius: 14px;
  background-color: $delpfine_dark_blue;
  border: 2px solid $delpfine_dark_blue;

  >p {
    font-weight: 700;
    // font-family: LondrinaSolid-Light;
    // font-size: 1.2em;
  }

  &:hover {
    background-color: white;
    color: $delpfine_dark_blue;
  }
}

.btn_secondary {
  cursor: pointer;
  width: fit-content;
  margin: 1em;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  color: white;
  border-radius: 14px;


  background-color: white;
  border: 2px solid $delpfine_dark_blue;
  color: $delpfine_dark_blue;

  >p {
    font-weight: 700;
    // font-family: LondrinaSolid-Light;
    // font-size: 1.2em;
  }

  &:hover {
    background-color: $delpfine_dark_blue;
    color: white;
  }
}

.container-sentiments-personnels {
  text-align: center;
  height: 35vh;
}

.container-reprise-en-mains {
  margin-top: 15em !important;
  padding: 1em;
  position: relative;

  .img_stop {
    position: absolute;
    top: -35vh;
  }

  >div {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1em;
    max-width: 900px;
    padding: 1em;
    padding-top: 5em;
    background: #a8ecffa9;
    background-image: repeating-linear-gradient(30deg,
        hsla(0, 0%, 100%, 0.1),
        hsla(0, 0%, 100%, 0.1) 15px,
        transparent 0,
        transparent 30px);
  }
}

@media screen and (max-width: 1230px) {
  .meandre_text {
    font-size: 2em !important;
  }
}

@media screen and (max-width: 850px) {
  .modalites>div>.image_hover>p {
    max-width: 550px !important;
    font-size: 1.7em !important;
  }

  .meandre_text {
    width: 70vw !important;
    top: 50% !important;
    transform: translate(-20%, -50%) !important;
    font-size: 1.5em !important;
  }

  .cards_methodologie {
    flex-direction: column !important;

    .card {
      width: 90vw !important;
    }
  }
}

@media screen and (min-width: 1180px) {
  .modalites>div>.image_hover>p {
    font-size: 2em !important;
  }
}

@media screen and (max-width: 550px) {
  .modalites>div>.image_hover>p {
    max-width: 350px !important;
    font-size: 1.5em !important;
  }

  .sub_container {
    margin: 1em 0;
  }

  .meandre_text {
    width: unset !important;
    transform: translate(0%, -50%) !important;
    left: 1em !important;
    right: 1em !important;
  }
}

@media screen and (max-width: 400px) {
  .img_stop {
    width: 300px;
    top: -26vh !important;
  }

  .meandre_text {
    top: 45% !important;
    font-size: 1.3em !important;
  }
}